import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg'
import { H1 } from '../components/shared/type'
import Container from '../components/shared/container'
import { slugify } from '../utils'
import { mediaQueries } from '../theme'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import PostList from '../components/resources/post-list'
import FooterCta from '../components/footer-cta'
import Link from '../components/shared/link'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[18]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[29]};
  }
`

const Posts = styled(PostList)`
  margin-bottom: ${p => p.theme.space[21]};
`

const Title = styled(H1)`
  margin: 0 0 ${p => p.theme.space[8]};
  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[21]};
  }
`

const Subtitle = styled.span`
  color: ${p => p.theme.colors.blue[0]};
`

const Arrow = styled(ArrowIcon)`
  display: inline-block;
  transform: rotate(-90deg);
  width: 25px;
  height: 25px;
  margin: 0 15px;
  ${mediaQueries.lg} {
    width: 50px;
    height: 50px;
  }
`

const CategoryTemplate = ({ data, location }) => {
  const { posts } = data.allMdx
  const categories = [
    ...data.allBlogCategoriesYaml.categories,
    ...data.allUseCasesCategoriesYaml.categories,
  ]
  const resource = posts[0]?.frontmatter?.resource
  const resourcePath = slugify(resource)
  const title = posts[0]?.frontmatter?.category
  const category = categories.find(c => c.title === title) || {}

  const { metaTitle, description } = category

  return (
    <Layout location={location}>
      <PageMetadata title={metaTitle} description={description} />
      <InnerContainer>
        <Title>
          <Link variant="nav" to={`/${resourcePath}/`}>
            {resource}
          </Link>
          <Arrow />
          <Subtitle>{title}</Subtitle>
        </Title>
        <Posts posts={posts} />
      </InnerContainer>
      <FooterCta />
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query Category($categorySlug: String!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { categorySlug: { eq: $categorySlug } } }
    ) {
      posts: nodes {
        ...postPreviewFragment
      }
    }
    allBlogCategoriesYaml {
      categories: nodes {
        title
        metaTitle
        description
      }
    }
    allUseCasesCategoriesYaml {
      categories: nodes {
        title
        metaTitle
        description
      }
    }
  }
`
